$(function(){

  /*
  * スクロールバーの装飾
  * jQuery Plugin
  */
  $('nav').mCustomScrollbar({ theme: 'minimal-dark' });



  /*
  * Drawer Menu
  */
  const baseContents = document.getElementById( 'base_contents' );
  const mainContents = document.getElementById( 'main_contents' );

  // バーガーメニュー
  const burgerObj = '#burger, .burger-icon, #burger span';

  // メニューオープン時につける class
  const openMenuClass = 'side_menu_open';
/*
  burger.addEventListener('click', function(){
    //baseContents.classList.toggle( openMenuClass );
    document.body.classList.toggle( openMenuClass );
  });
*/
  mainContents.addEventListener('click', function( event ){
    if ( ! matches( event.target, burgerObj ) && $('body').hasClass( openMenuClass ) ) {
      document.body.classList.remove( openMenuClass );
    } else if ( matches( event.target, burgerObj ) ) {
      document.body.classList.toggle( openMenuClass );
    }
  });


  // jQuery .is() -> JavaScript
  const matches = function( el, selector ) {
    return ( el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector ).call( el, selector );
  };


  // navigation Child Menu
  const child_trigger = '.is_child_menu';

  $( child_trigger ).children('a').on('click', function( event ){

    event.preventDefault();

    $(this).toggleClass('child_menu_open').next('.side_navigation_child').slideToggle(200).end().parent(child_trigger).siblings(child_trigger).children('a').removeClass('child_menu_open').next('.side_navigation_child').slideUp(200);

  });

});
